export const types = {
  GET_ALL_GROUPS_REQUEST: 'GET_ALL_GROUPS_REQUEST',
  GET_ALL_GROUPS_SUCCESS: 'GET_ALL_GROUPS_SUCCESS',
  GET_ALL_GROUPS_FAILURE: 'GET_ALL_GROUPS_FAILURE',

  GET_GROUP_DETAILS_REQUEST: 'GET_GROUP_DETAILS_REQUEST',
  GET_GROUP_DETAILS_SUCCESS: 'GET_GROUP_DETAILS_SUCCESS',
  GET_GROUP_DETAILS_FAILURE: 'GET_GROUP_DETAILS_FAILURE',

  REMOVE_USER_FROM_GROUP_REQUEST: 'REMOVE_USER_FROM_GROUP_REQUEST',
  REMOVE_USER_FROM_GROUP_SUCCESS: 'REMOVE_USER_FROM_GROUP_SUCCESS',
  REMOVE_USER_FROM_GROUP_FAILURE: 'REMOVE_USER_FROM_GROUP_FAILURE',

  DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE: 'DELETE_GROUP_FAILURE',

  GET_GROUP_FEEDS_REQUEST: 'GET_GROUP_FEEDS_REQUEST',
  GET_GROUP_FEEDS_SUCCESS: 'GET_GROUP_FEEDS_SUCCESS',
  GET_GROUP_FEEDS_FAILURE: 'GET_GROUP_FEEDS_FAILURE',

  EDIT_POST_REQUEST: 'EDIT_POST_REQUEST',
  EDIT_POST_SUCCESS: 'EDIT_POST_SUCCESS',
  EDIT_POST_FAILURE: 'EDIT_POST_FAILURE',

  GET_HASH_TAGS_REQUEST: 'GET_HASH_TAGS_REQUEST',
  GET_HASH_TAGS_SUCCESS: 'GET_HASH_TAGS_SUCCESS',
  GET_HASH_TAGS_FAILURE: 'GET_HASH_TAGS_FAILURE',

  MAKE_USER_ADMIN_REQUEST: 'MAKE_USER_ADMIN_REQUEST',
  MAKE_USER_ADMIN_SUCCESS: 'MAKE_USER_ADMIN_SUCCESS',
  MAKE_USER_ADMIN_FAILURE: 'MAKE_USER_ADMIN_FAILURE',

  DEACTIVATE_GROUP_REQUEST: 'DEACTIVATE_GROUP_REQUEST',
  DEACTIVATE_GROUP_SUCCESS: 'DEACTIVATE_GROUP_SUCCESS',
  DEACTIVATE_GROUP_FAILURE: 'DEACTIVATE_GROUP_FAILURE',

  HANDLE_GROUP_KEYS: 'HANDLE_GROUP_KEYS'
}
