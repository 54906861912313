import _filter from 'lodash/filter'
import Moment from 'moment';
import { stripHtml } from "string-strip-html";

import { removeStorage } from '../routes/localStorage'
import Path from '../constants/path'
import { COUNTRIES, IMAGE_BASE_URL } from '../constants/index'
import Strings from 'src/constants/Strings';

const logout = (history) => {
    removeStorage('isLogged');
    removeStorage('userInfo');
    history.push(Path.login)
}

const getFormattedDate = (date) => {
    if (date) {
        try {
            return Moment(date).format("MM/DD/YYYY")
        } catch (error) {
            return ''
        }
    } else {
        return ''
    }
}

const getDateObject = date => {
    if (date.unix) {
        return Moment.unix(date.unix).format('YYYY-MM-DD')
    } else {
        return Moment(date).format('YYYY-MM-DD')
    }
}

const getTotalTablePages = (totalRecords, pageSize) => {
    return Math.ceil(totalRecords / pageSize)
}

const getCountry = (code) => {
    if (code) {
        let country = _filter(COUNTRIES, item => item.value === Number(code))
        if (country.length) {
            return country[0].name
        } else {
            return ''
        }
    } else {
        return ''
    }
}

const handleEnterKeyPress = (event, func) => {
    var code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
        //Do stuff in here
        func(event)
    }
}

function getFormattedDateWithYear(postDate) {
    try {
        return Moment(postDate).format("MM/DD/YYYY hh:mm:ss A")
    }
    catch (error) {
        return ''
    }
}

export function getFormattedDateTime(postDate) {
    try {
        return Moment(postDate).format("MM/DD/YYYY hh:mm A")
    }
    catch (error) {
        return ''
    }
}
function getDefaultGroupProfileImage() {
    return getBase64ImageFromUrl('/images/Default_GroupAvathar.png');
};

function getDefaultGroupCoverImage() {
    return getBase64ImageFromUrl('/images/Default_GroupCover.jpg');
};

async function getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
    var file = new File([blob], `default_profile_image.png`, {
        type: 'image/jpeg',
        lastModified: Date.now()
    });
    return file;

}
export const getStripedHtml = (HTMLdata) => {
    return stripHtml(HTMLdata).result;
}
export const getVideoUrl = (path) => {
    let mp4 = path.replace(/\.jpg$/i, '.mp4');
    let videoUrl = IMAGE_BASE_URL + mp4;
    return videoUrl;
}
export const getWeightPrivacy = (privacy) => {
    if (privacy === 1) {
        return true
    } else {
        return false
    }
}
export const getFeedKey = (data) => {
    let selectedFeedKey = ''
    if (data.strMethod === 'getreportedpost') {
        selectedFeedKey = 'reportedPosts'
    } else if (data.strMethod === 'getsingleuserposts') {
        if (data.IsReviewed) {
            selectedFeedKey = 'allPosts'
        } else {
            selectedFeedKey = 'postsToReview'
        }
    }
    return selectedFeedKey
}
export const getProfilePicUrl = (url) => {
    let regex = /(http(s?)):\/\//i;
    if (regex.test(url)) {
      return url
    } else {
      return `${IMAGE_BASE_URL}${url}`
    }
  }
export const getImageUrl = (contentType, url) => {
    let imageUrl
    if (contentType === Strings.global.CONTENT_IMAGE) {
      imageUrl = IMAGE_BASE_URL + url
    }
    else {
      imageUrl = url
    }
    return imageUrl
  }
  
const functions = {
    logout,
    getFormattedDate,
    getFormattedDateWithYear,
    getFormattedDateTime,
    getCountry,
    getTotalTablePages,
    getDateObject,
    handleEnterKeyPress,
    getDefaultGroupProfileImage,
    getDefaultGroupCoverImage,
    getStripedHtml,
    getVideoUrl,
    getWeightPrivacy,
    getFeedKey,
    getProfilePicUrl,
    getImageUrl
}

export default functions;