const Strings = {
    login: {
        login: 'Login',
        wlbAdmin: 'WLB-Admin',
        signInWithAdmin: 'Sign In to your admin account',
        adminControlPanel: 'Admin Control Panel',
        tokenExpired: 'Token Expired',
        sesisonExpiredMsg: 'Your Login session expired. Please login again to continue',
    },
    required: {
        userNameRequired: 'UserName is required',
        passwordRequired: 'Password is required',
        firstNameRequired: 'FirstName is required',
        genderRequired: 'Gender required',
        ageRequired: 'Age required',
        dobRequired: 'Invalid Date, Date Of Birth Required',
        validDOB: 'Invalid DOB, Year should be greater than 1800',
        AGE_APPROVE_MESSAGE: "Age must be greater than 15",
        emailRequired: 'Email is required',
        invalidEmail: 'Invalid Email',
        titleRequired: 'Post content required',
        pleaseChooseAnyOption: 'Please choose any option',
        enterYourReason: 'Please enter the reason',
        chooseTheReasonTo: 'Choose the reason to'
    },
    header: {
        welcome: 'Welcome',
        wlbSite: 'WLB Site',
        logout: 'Logout',
        logoutMessage: 'Are you sure want to logout?',
        group_Details: 'Group Details',
        Group_List: 'Group List',
        deleted_group: 'Deleted Group',
    },
    global: {
        cancel: 'Cancel',
        delete: 'Delete',
        close: 'Close',
        ok: 'Okay',
        yes: 'Yes',
        submit: 'Submit',
        description: 'Description',
        save: 'Save',
        View_Details: 'View details',
        View_Posts: 'View posts',
        deactivate: 'Deactivate',
        activate: 'Activate',
        reviewed: 'Reviewed',
        yetToReview: 'Yet to be Reviewed',
        active: 'active',
        inactive: 'inactive',
        blurInactivePost: 'Blur Inactive Post',
        restorePost: 'Restore Post',
        deletePost: 'Delete Post',
        approveReport: 'Approve Report',
        deleteReportUser: 'Delete Report User',
        deleteCommentUser: 'Delete Comment User',
        rejectReport: 'Reject Report',
        approveReportMsg: 'Are you sure want to approve this reported post. This will remove the post permanently for the users.',
        rejectReportMsg: 'Are you sure want to reject this reported post. This will make the post visible to the users.',
        approveReportedCommentMsg: 'Are you sure want to approve this reported comment. This will remove the comment permanently for the users.',
        rejectReportedCommentMsg: 'Are you sure want to reject this reported comment. This will make the comment visible to the users.',
        reviewComments: 'Your review comments here!',
        SW: 'SW',
        CW: 'CW',
        GW: 'GW',
        SHOW_MORE: 'Show more',
        CONTENT_GIF: 'ContentGif',
        CONTENT_IMAGE: "ContentImage",
        approveReportedComment: 'Approve Reported Comment',
        rejectReportedComment: 'Reject Reported Comment',
        abilityToMakePremium:'Ability to Make Premium',
        abilityToChangeExpiry:'Ability to change  expiration',
        approveBulkReported: 'Approve Bulk Reported',
        rejectBulkReported: 'Reject Bulk Reported',
        approveBulkReportMsg: 'Are you sure want to approve these reported posts/comments. This will remove these posts/comments permanently for the users',
        rejectBulkReportMsg: 'Are you sure want to reject these reported posts/comments. This will make the  posts/comments visible to the users.',
    },
    dashboard: {
        selectDateRange: 'Select Date range',
        userLoggedInDR: 'User Logged In Date Range',
        newUsersInDR: 'New Users In Date Range',
        confirmedInDR: 'Confirmed In Date Range',
        totalPostsInDR: 'Total Posts In Date Range',
        uniquePostsInDR: 'Unique Posters In Date Range',
        returnUsersInDR: 'Return Users In Date Range',
        timeOnlineInDR: 'Time Online In Date Range',
        totalcomments: "Total Comments In Date Range",
        totalshares: "Total Shares In Date Range",
        title: 'Title',
        count: 'Count',
        allUsers: 'All Users',
        activeUsers: 'Active Users',
        usersloggedInToday: 'Users logged-in today',
        usersloggedInYesterday: 'Users logged-in yesterday',
        usersloggedInThisMonth: 'Users logged-in this month',
        usersloggedIn30d: 'Users logged in past-30 days',
        numberOfTeams: 'Number of teams',
        snNewsSignup: 'SN News Letter Sign-up',
        newUsersToday: 'New Users Today',
        newUsersYesterday: 'New Users Yesterday',
        newUsersthisMonth: 'New Users this month',
        newUsersPast30d: 'New Users past 30 days',
        desktop: 'Desktop',
        mobile: 'Mobile',
        dashboard: 'Dashboard',
        totalPostsToday: 'Total Posts today',
        totalPostsYesterday: 'Total Posts yesterday',
        totalPostsThisMonth: 'Total Posts this month',
        totalPostsPast30d: 'Total Posts past 30days',
        uniquePostsToday: 'Unique Posters today',
        uniquePostsYesterday: 'Unique Posters yesterday',
        uniquePostsThisMonth: 'Unique Posters this month',
        uniquePostsPast30d: 'Unique Posters past 30days',
        returnUsersToday: 'Return Users today',
        returnUsersYesterday: 'Return Users yesterday',
        returnUsersThisMonth: 'Return Users this month',
        returnUsersPast30d: 'Return Users past 30days',
        timeOnlineToday: 'Time Online today',
        timeOnlineYesterday: 'Time Online yesterday',
        timeOnlineThisMonth: 'Time Online this month',
        timeOnlinePast30d: 'Time Online past 30days',
        ThreeDayFreeTrial:'3 Day Free Trial',
        Premium:'Premium',
        Paid:'$ Paid',    
    },
    users: {
        userManagement: 'User Management',
        deleteUser: 'Delete User',
        deletePostUser: 'Delete Post User',
        signInUser: 'Sign in as user',
        deletedUser: 'Deleted User',
        editUserInfo: 'Edit basic information',
        userPosts: 'User Posts',
        posts: 'Posts',
        allPosts: 'All Posts',
        yetToBeReviewed: 'Yet to be Reviewed',
        reportedPosts: 'Reported Posts',
        reportedBy: 'Reported by',
        loginInfo: 'Login info',
        activationInfo: 'Activation info',
        reportedComments: 'Reported Comments',
        activateUser: 'Activate User',
        deActivateUser: 'Deactivate User',
        commentsHere: 'Comments here ...',
    },
    groups: {
        sideBarName: 'Groups',
        private: 'Private',
        public: 'Public',
        group_description: 'Group Description',
        group_name: 'Group Name',
        group_type: 'Group Type',
        groupNameRequired: 'Group Name is required',
        groupDescRequired: 'Group Description is required',
        alphaNumericAllowed: 'Only alphabets and numbers are allowed in Group Name',
        groupNameMaxLength: 'Group Name should not exceed more than 200 characters.',
        users: 'Users',
        posts: 'Posts',
        likes: 'Likes',
        like: 'Like',
        comments: 'Comments',
        comment: 'Comment',
        SW: 'SW',
        CW: 'CW',
        GW: 'GW',
        showMore: 'Show more',
        noCommentFound: 'No comment found',
        noPostsFound: 'No posts found',
        noRecordsFound: 'No records found',
        referLink: 'Refer Link',
        editPost: 'Edit post',
        title: 'Title',
        link: 'Link',
        askRequestToJoin: 'Ask request to join',
        displayFeedsInGroup: 'Display posts only in groups',
        adminApprovalForPost: 'Admin approval on new post',
        approved: 'approved',
        pending: 'pending',
        delete_user_alert: 'Are you sure you want to delete',
        delete_only_admin_alert: 'Group should have at least one admin. So you cannot delete this user',
        make_post_inactive: 'Make Post inactive',
        make_post_active: 'Make Post active',
        edit_post: 'Edit Post',
        enter_post_content: 'Enter Post content',
        add_hashtag: 'Add Hashtag',
        delete_group_alert: 'Are you sure you want to delete',
        deactivate_group_alert: 'Are you sure you want to deactivate',
        activate_group_alert: 'Are you sure you want to activate',
        makeAdmin: 'Make as Admin',
        makeAdminNotificationMsg: 'Are you sure want to make',
        deleted_group_alert: 'is deleted',
        deleted_group: 'Deleted Group',
        active_group: 'Active Group',
        delete_group: 'Delete Group',
        deactivate_group: 'Deactivate Group',
        activate_group: 'Activate Group',
        group_deactivate_alert: 'No Posts on last few days.',
        group_deactivate_message: 'Group has been deactivated.'
    },
    userStatistics: {
        statistics: 'Statistics',
        day: 'Day',
        month: 'Month',
        week: "Week",
        all: 'All',
        custom: 'Custom',
        date: 'Date',
    },
    premiumUser: {
        premium: 'Premium'
    },

}

export default Strings;