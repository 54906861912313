import axios from 'axios';
import { API_BASE_URL } from '../constants'
import { getStorage } from '../routes/localStorage'
import store from '../store';
import { handleKeys } from './actions/UserAction';

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});



const request = (options) => {
    if (getStorage('isLogged')) {
        const userToken = getStorage('isLogged');
        client.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
    } else {
        delete client.defaults.headers.common['Authorization'];
    }
    return client(options)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const errorHandler = (error) => {
    if (error.response && error.response.status) {
        if (error.response.status === 401) {
            store.dispatch(handleKeys('displaySessionAlert', true))
        }
    }
    else {
        return Promise.reject('Network error');
    }
}
export const get = (url) => request({ url, method: 'get' });

export const post = (url, data) => {
    const date = new Date();
    return request({
        url,
        method: 'post',
        data: {
            device_type: 'browser',
            TimezoneOffset: date.getTimezoneOffset(),
            ...data
        }
    });
}

export const fileUpload = (url, file, method = 'post') => {
    const date = new Date();
    const data = new FormData();
    data.append('device_type', 'browser');
    data.append('device_token', window.navigator.appVersion);
    data.append('TimezoneOffset', date.getTimezoneOffset());

    for (var key in file) {
        data.append(key, file[key]);
    }
    return request({
        url,
        data,
        method,
        headers: { 'content-type': 'multipart/form-data' }
    });
};
