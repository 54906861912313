const Path = {
    basePath: '/',
    login: '/AdminLogin',
    dashboard: '/Dashboard',
    users: '/Users',
    usersPost: '/UsersPost',
    groups: '/Groups',
    statistics: '/Statistics',
    posts: '/Posts',
}

export default Path;