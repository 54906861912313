import React from 'react';
import { Switch } from 'react-router-dom';
import Path from 'src/constants/path';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';


const Login = React.lazy(() => import('../views/pages/login/Login'));
const TheLayout = React.lazy(() => import('../containers/TheLayout'));


function Routes(props) {
    return <Switch>
        <PublicRoute path={Path.login} name="Login Page" component={Login} />
        <PrivateRoute path={Path.basePath} name="Home" component={TheLayout} />
    </Switch>
}

export default Routes;
