import { toast } from 'react-toastify';
const options = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined
}
const ToastSuccess = (props) => toast.success(props, options);

const ToastWarning = (props) => toast.warning(props, options);

const ToastError = (props) => toast.error(props, options);


const successToastId = {};
const warningToastId = {};
const errorToastId = {};

const notifySuccessMsg = (msg) => {
    if (!toast.isActive(successToastId.current)) {
        successToastId.current = ToastSuccess(msg);
    }
}

const notifyWarningMsg = (msg) => {
    if (!toast.isActive(warningToastId.current)) {
        warningToastId.current = ToastWarning(msg);
    }
}

const notifyErrorMsg = (msg) => {
    if (!toast.isActive(errorToastId.current)) {
        errorToastId.current = ToastError(msg);
    }
}


const Toast = {
    notifySuccessMsg,
    notifyErrorMsg,
    notifyWarningMsg
}
export default Toast