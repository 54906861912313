import React from 'react';

export default function Spinner({loading=false}) {
  if(loading) {
    return (
     <span className="spinner-border spinner-border-sm marginHorizontal5" role="status" aria-hidden="true" />
  );
    } else {
      return null
    }
}
