export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL

// export const API_BASE_URL= 'https://www.weightlossbuddy.com/wlbapi/api/';
// export const IMAGE_BASE_URL= 'https://www.weightlossbuddy.com/WLBImages/wlbuploads'

export const Endpoint = {
    getDashboardData: 'Admin/DashboardNew',
    login: 'Admin/Login',
    getUsers: 'Admin/GetUsers',
    getUserDetails: 'Admin/GetUser',
    deleteUser: 'Admin/DeleteUsers',
    updateUserDetails: 'Admin/UpdateUser',
    getAllGroups: 'Group/AllGroups',
    getGroupDetails: 'Group/GetGroupDetailsForAdmin',
    editGroup: 'Group/Edit',
    removeUser: 'Group/RemoveUserFromGroup',
    deleteGroup: 'Group/DeleteGroup',
    getGroupFeeds: 'Group/GetFeedsForGroup',
    editPost: 'Feeds/UpdatePost',
    getAllHashTags: 'Feeds/GetAllTags',
    updatePostStatus: 'Admin/UpdatePosts',
    makeUserAdmin: 'Group/MakeUserAsAdmin',
    getUserStatistics: 'Admin/GetUsersStatistics',
    getAllPosts: 'Feeds/GetFeeds',
    getUserInfo: 'MemberDetails/GetUserInfo',
    updateReportedPostStatus: 'Admin/UpdateReportedPost',
    abilityToMakePremium: 'Admin/AIMakeChatPremium',
    abilityToChangeExpiry: 'Admin/AIUpdateChatExpiration',
    GetAIMembershipTariffs: 'AI/GetAIMembershipTariffs',
    updateBulkReportedPostStatus: 'Admin/BulkUpdateReportedPostOrComment',
}

export const COUNTRIES = [
    { value: 240, name: 'United States', code: 'US' },
    { value: 241, name: 'Canada', code: 'CA' },
    { value: 222, name: 'United Kingdom', code: 'GB' },
    { value: 1, name: 'Afghanistan', code: 'AF' },
    { value: 2, name: 'Albania', code: 'AL' },
    { value: 3, name: 'Algeria', code: 'DZ' },
    { value: 4, name: 'American Samoa', code: 'AS' },
    { value: 5, name: 'Andorra', code: 'AD' },
    { value: 6, name: 'Angola', code: 'AO' },
    { value: 7, name: 'Anguilla', code: 'AI' },
    { value: 8, name: 'Antarctica', code: 'AQ' },
    { value: 9, name: 'Antigua and Barbuda', code: 'AG' },
    { value: 10, name: 'Argentina', code: 'AR' },
    { value: 11, name: 'Armenia', code: 'AM' },
    { value: 12, name: 'Aruba', code: 'AW' },
    { value: 13, name: 'Australia', code: 'AU' },
    { value: 14, name: 'Austria', code: 'AT' },
    { value: 15, name: 'Azerbaijan', code: 'AZ' },
    { value: 16, name: 'Bahamas', code: 'BS' },
    { value: 17, name: 'Bahrain', code: 'BH' },
    { value: 18, name: 'Bangladesh', code: 'BD' },
    { value: 19, name: 'Barbados', code: 'BB' },
    { value: 20, name: 'Belarus', code: 'BY' },
    { value: 21, name: 'Belgium', code: 'BE' },
    { value: 22, name: 'Belize', code: 'BZ' },
    { value: 23, name: 'Benin', code: 'BJ' },
    { value: 24, name: 'Bermuda', code: 'BM' },
    { value: 25, name: 'Bhutan', code: 'BT' },
    { value: 26, name: 'Bolivia', code: 'BO' },
    { value: 27, name: 'Bosnia and Herzegowina', code: 'BA' },
    { value: 28, name: 'Botswana', code: 'BW' },
    { value: 29, name: 'Bouvet Island', code: 'BV' },
    { value: 30, name: 'Brazil', code: 'BR' },
    { value: 31, name: 'British Indian Ocean Territory', code: 'IO' },
    { value: 32, name: 'Brunei Darussalam', code: 'BN' },
    { value: 33, name: 'Bulgaria', code: 'BG' },
    { value: 34, name: 'Burkina Faso', code: 'BF' },
    { value: 35, name: 'Burundi', code: 'BI' },
    { value: 36, name: 'Cambodia', code: 'KH' },
    { value: 37, name: 'Cameroon', code: 'CM' },
    { value: 39, name: 'Cape Verde', code: 'CV' },
    { value: 40, name: 'Cayman Islands', code: 'KY' },
    { value: 41, name: 'Central African Republic', code: 'CF' },
    { value: 42, name: 'Chad', code: 'TD' },
    { value: 43, name: 'Chile', code: 'CL' },
    { value: 44, name: 'China', code: 'CN' },
    { value: 45, name: 'Christmas Island', code: 'CX' },
    { value: 46, name: 'Cocos (Keeling) Islands', code: 'CC' },
    { value: 47, name: 'Colombia', code: 'CO' },
    { value: 48, name: 'Comoros', code: 'KM' },
    { value: 49, name: 'Congo', code: 'CG' },
    { value: 50, name: 'Cook Islands', code: 'CK' },
    { value: 51, name: 'Costa Rica', code: 'CR' },
    { value: 52, name: "Cote D'Ivoire", code: 'CI' },
    { value: 53, name: 'Croatia', code: 'HR' },
    { value: 54, name: 'Cuba', code: 'CU' },
    { value: 55, name: 'Cyprus', code: 'CY' },
    { value: 56, name: 'Czech Republic', code: 'CZ' },
    { value: 57, name: 'Denmark', code: 'DK' },
    { value: 58, name: 'Djibouti', code: 'DJ' },
    { value: 59, name: 'Dominica', code: 'DM' },
    { value: 60, name: 'Dominican Republic', code: 'DO' },
    { value: 61, name: 'East Timor', code: 'TL' },
    { value: 62, name: 'Ecuador', code: 'EC' },
    { value: 63, name: 'Egypt', code: 'EG' },
    { value: 64, name: 'El Salvador', code: 'SV' },
    { value: 65, name: 'Equatorial Guinea', code: 'GQ' },
    { value: 66, name: 'Eritrea', code: 'ER' },
    { value: 67, name: 'Estonia', code: 'EE' },
    { value: 68, name: 'Ethiopia', code: 'ET' },
    { value: 69, name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { value: 70, name: 'Faroe Islands', code: 'FO' },
    { value: 71, name: 'Fiji', code: 'FJ' },
    { value: 72, name: 'Finland', code: 'FI' },
    { value: 73, name: 'France', code: 'FR' },
    { value: 74, name: 'France, Metropolitan', code: 'FX' },
    { value: 75, name: 'French Guiana', code: 'GF' },
    { value: 76, name: 'French Polynesia', code: 'PF' },
    { value: 77, name: 'French Southern Territories', code: 'TF' },
    { value: 78, name: 'Gabon', code: 'GA' },
    { value: 79, name: 'Gambia', code: 'GM' },
    { value: 80, name: 'Georgia', code: 'GE' },
    { value: 81, name: 'Germany', code: 'DE' },
    { value: 82, name: 'Ghana', code: 'GH' },
    { value: 83, name: 'Gibraltar', code: 'GI' },
    { value: 84, name: 'Greece', code: 'GR' },
    { value: 85, name: 'Greenland', code: 'GL' },
    { value: 86, name: 'Grenada', code: 'GD' },
    { value: 87, name: 'Guadeloupe', code: 'GP' },
    { value: 88, name: 'Guam', code: 'GU' },
    { value: 89, name: 'Guatemala', code: 'GT' },
    { value: 90, name: 'Guinea', code: 'GN' },
    { value: 91, name: 'Guinea-bissau', code: 'GW' },
    { value: 92, name: 'Guyana', code: 'GY' },
    { value: 93, name: 'Haiti', code: 'HT' },
    { value: 94, name: 'Heard and Mc Donald Islands', code: 'HM' },
    { value: 95, name: 'Honduras', code: 'HN' },
    { value: 96, name: 'Hong Kong', code: 'HK' },
    { value: 97, name: 'Hungary', code: 'HU' },
    { value: 98, name: 'Iceland', code: 'IS' },
    { value: 99, name: 'India', code: 'IN' },
    { value: 100, name: 'Indonesia', code: 'ID' },
    { value: 101, name: 'Iran (Islamic Republic of)', code: 'IR' },
    { value: 102, name: 'Iraq', code: 'IQ' },
    { value: 103, name: 'Ireland', code: 'IE' },
    { value: 104, name: 'Israel', code: 'IL' },
    { value: 105, name: 'Italy', code: 'IT' },
    { value: 106, name: 'Jamaica', code: 'JM' },
    { value: 107, name: 'Japan', code: 'JP' },
    { value: 108, name: 'Jordan', code: 'JO' },
    { value: 109, name: 'Kazakhstan', code: 'KZ' },
    { value: 110, name: 'Kenya', code: 'KE' },
    { value: 111, name: 'Kiribati', code: 'KI' },
    { value: 112, name: "Korea, Democratic People's Republic of", code: 'KP' },
    { value: 113, name: 'Korea, Republic of', code: 'KR' },
    { value: 114, name: 'Kuwait', code: 'KW' },
    { value: 115, name: 'Kyrgyzstan', code: 'KG' },
    { value: 116, name: "Lao People's Democratic Republic", code: 'LA' },
    { value: 117, name: 'Latvia', code: 'LV' },
    { value: 118, name: 'Lebanon', code: 'LB' },
    { value: 119, name: 'Lesotho', code: 'LS' },
    { value: 120, name: 'Liberia', code: 'LR' },
    { value: 121, name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { value: 122, name: 'Liechtenstein', code: 'LI' },
    { value: 123, name: 'Lithuania', code: 'LT' },
    { value: 124, name: 'Luxembourg', code: 'LU' },
    { value: 125, name: 'Macau', code: 'MO' },
    { value: 126, name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { value: 127, name: 'Madagascar', code: 'MG' },
    { value: 128, name: 'Malawi', code: 'MW' },
    { value: 129, name: 'Malaysia', code: 'MY' },
    { value: 130, name: 'Maldives', code: 'MV' },
    { value: 131, name: 'Mali', code: 'ML' },
    { value: 132, name: 'Malta', code: 'MT' },
    { value: 133, name: 'Marshall Islands', code: 'MH' },
    { value: 134, name: 'Martinique', code: 'MQ' },
    { value: 135, name: 'Mauritania', code: 'MR' },
    { value: 136, name: 'Mauritius', code: 'MU' },
    { value: 137, name: 'Mayotte', code: 'YT' },
    { value: 138, name: 'Mexico', code: 'MX' },
    { value: 139, name: 'Micronesia, Federated States of', code: 'FM' },
    { value: 140, name: 'Moldova, Republic of', code: 'MD' },
    { value: 141, name: 'Monaco', code: 'MC' },
    { value: 142, name: 'Mongolia', code: 'MN' },
    { value: 143, name: 'Montserrat', code: 'MS' },
    { value: 144, name: 'Morocco', code: 'MA' },
    { value: 145, name: 'Mozambique', code: 'MZ' },
    { value: 146, name: 'Myanmar', code: 'MM' },
    { value: 147, name: 'Namibia', code: 'NA' },
    { value: 148, name: 'Nauru', code: 'NR' },
    { value: 149, name: 'Nepal', code: 'NP' },
    { value: 150, name: 'Netherlands', code: 'NL' },
    { value: 151, name: 'Netherlands Antilles', code: 'AN' },
    { value: 152, name: 'New Caledonia', code: 'NC' },
    { value: 153, name: 'New Zealand', code: 'NZ' },
    { value: 154, name: 'Nicaragua', code: 'NI' },
    { value: 155, name: 'Niger', code: 'NE' },
    { value: 156, name: 'Nigeria', code: 'NG' },
    { value: 157, name: 'Niue', code: 'NU' },
    { value: 158, name: 'Norfolk Island', code: 'NF' },
    { value: 159, name: 'Northern Mariana Islands', code: 'MP' },
    { value: 160, name: 'Norway', code: 'NO' },
    { value: 161, name: 'Oman', code: 'OM' },
    { value: 162, name: 'Pakistan', code: 'PK' },
    { value: 163, name: 'Palau', code: 'PW' },
    { value: 164, name: 'Panama', code: 'PA' },
    { value: 165, name: 'Papua New Guinea', code: 'PG' },
    { value: 166, name: 'Paraguay', code: 'PY' },
    { value: 167, name: 'Peru', code: 'PE' },
    { value: 168, name: 'Philippines', code: 'PH' },
    { value: 169, name: 'Pitcairn', code: 'PN' },
    { value: 170, name: 'Poland', code: 'PL' },
    { value: 171, name: 'Portugal', code: 'PT' },
    { value: 172, name: 'Puerto Rico', code: 'PR' },
    { value: 174, name: 'Reunion', code: 'RE' },
    { value: 175, name: 'Romania', code: 'RO' },
    { value: 176, name: 'Russian Federation', code: 'RU' },
    { value: 177, name: 'Rwanda', code: 'RW' },
    { value: 178, name: 'Saint Kitts and Nevis', code: 'KN' },
    { value: 179, name: 'Saint Lucia', code: 'LC' },
    { value: 180, name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { value: 181, name: 'Samoa', code: 'WS' },
    { value: 182, name: 'San Marino', code: 'SM' },
    { value: 183, name: 'Sao Tome and Principe', code: 'ST' },
    { value: 184, name: 'Saudi Arabia', code: 'SA' },
    { value: 185, name: 'Senegal', code: 'SN' },
    { value: 186, name: 'Seychelles', code: 'SC' },
    { value: 187, name: 'Sierra Leone', code: 'SL' },
    { value: 188, name: 'Singapore', code: 'SG' },
    { value: 189, name: 'Slovakia (Slovak Republic)', code: 'SK' },
    { value: 190, name: 'Slovenia', code: 'SI' },
    { value: 191, name: 'Solomon Islands', code: 'SB' },
    { value: 192, name: 'Somalia', code: 'SO' },
    { value: 193, name: 'South Africa', code: 'ZA' },
    { value: 194, name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { value: 195, name: 'Spain', code: 'ES' },
    { value: 196, name: 'Sri Lanka', code: 'LK' },
    { value: 197, name: 'St. Helena', code: 'SH' },
    { value: 198, name: 'St. Pierre and Miquelon', code: 'PM' },
    { value: 199, name: 'Sudan', code: 'SD' },
    { value: 200, name: 'Suriname', code: 'SR' },
    { value: 201, name: 'Svalbard and Jan Mayen Islands', code: 'SJ' },
    { value: 202, name: 'Swaziland', code: 'SZ' },
    { value: 203, name: 'Sweden', code: 'SE' },
    { value: 204, name: 'Switzerland', code: 'CH' },
    { value: 205, name: 'Syrian Arab Republic', code: 'SY' },
    { value: 206, name: 'Taiwan', code: 'TW' },
    { value: 207, name: 'Tajikistan', code: 'TJ' },
    { value: 208, name: 'Tanzania, United Republic of', code: 'TZ' },
    { value: 209, name: 'Thailand', code: 'TH' },
    { value: 210, name: 'Togo', code: 'TG' },
    { value: 211, name: 'Tokelau', code: 'TK' },
    { value: 212, name: 'Tonga', code: 'TO' },
    { value: 213, name: 'Trinidad and Tobago', code: 'TT' },
    { value: 214, name: 'Tunisia', code: 'TN' },
    { value: 215, name: 'Turkey', code: 'TR' },
    { value: 216, name: 'Turkmenistan', code: 'TM' },
    { value: 217, name: 'Turks and Caicos Islands', code: 'TC' },
    { value: 218, name: 'Tuvalu', code: 'TV' },
    { value: 219, name: 'Uganda', code: 'UG' },
    { value: 220, name: 'Ukraine', code: 'UA' },
    { value: 221, name: 'United Arab Emirates', code: 'AE' },
    { value: 224, name: 'United States Minor Outlying Islands', code: 'UM' },
    { value: 225, name: 'Uruguay', code: 'UY' },
    { value: 226, name: 'Uzbekistan', code: 'UZ' },
    { value: 227, name: 'Vanuatu', code: 'VU' },
    { value: 228, name: 'Vatican City State (Holy See)', code: 'VA' },
    { value: 229, name: 'Venezuela', code: 'VE' },
    { value: 230, name: 'Viet Nam', code: 'VN' },
    { value: 231, name: 'Virgin Islands (British)', code: 'VG' },
    { value: 232, name: 'Virgin Islands (U.S.)', code: 'VI' },
    { value: 233, name: 'Wallis and Futuna Islands', code: 'WF' },
    { value: 234, name: 'Western Sahara', code: 'EH' },
    { value: 235, name: 'Yemen', code: 'YE' },
    { value: 236, name: 'Yugoslavia', code: 'YU' },
    { value: 237, name: 'Zaire', code: 'ZR' },
    { value: 238, name: 'Zambia', code: 'ZM' },
    { value: 239, name: 'Zimbabwe ', code: 'ZW' },
]

export const deleteUserOptions = [
    { label: 'Irrelevant posts by this user', reason: 'Irrelevant posts by this user' },
    { label: 'User posted adult contents', reason: 'User posted adult contents' },
    { label: "User hasn't logged-in for more than 60 days", reason: "User hasn't logged-in for more than 60 days" },
    { label: 'Others', reason: '' }
]

export const activateUserOptions = [
    { label: 'User request by email', reason: 'User request by email' },
    { label: 'Others', reason: '' }
]

export const TABLE_PAGE_SIZE = 50;

export const TOOL_TIP_POSITION = 'top'