import React from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'

import { handleKeys } from '../services/actions/UserAction';
import Strings from '../constants/Strings';
import AlertModal from './AlertModal';
import functions from './functions';


export default function SessionAlert() {

  const displaySessionAlert = useSelector((state) => state.user.displaySessionAlert)
  const dispatch = useDispatch()
  let history = useHistory();

  const handleSessionAlert = () => {
    dispatch(handleKeys('displaySessionAlert', false))
    functions.logout(history)
  }

  return (
    <AlertModal
      displayModal={displaySessionAlert}
      header={Strings.login.tokenExpired}
      message={Strings.login.sesisonExpiredMsg}
      hideCancelButton
      mainButtonLabel={Strings.global.ok}
      onMainButtonPress={handleSessionAlert}
    />
  );
}
