import _isEmpty from 'lodash/isEmpty'

import { types } from '../actionTypes/UserActionTypes'
import { post, get } from '../request'
import { Endpoint } from '../../constants/index'
import Toast from '../../containers/Toast'
import { getFeedKey } from '../../reusable/functions';

export const getUserList = data => dispatch => {
  dispatch({ type: types.GET_USERS_REQUEST });

  post(Endpoint.getUsers, data)
    .then(response => {
      if (response.status === 'success') {
        dispatch({ type: types.GET_USERS_SUCCESS, payload: response });
      } else {

      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.GET_USERS_FAILURE, payload: err });
    });
};

export const getUserDetails = data => dispatch => {
  dispatch({ type: types.GET_USER_DETAILS_REQUEST });
  post(Endpoint.getUserDetails, data)
    .then(response => {
      if (response.status === 'success') {
        dispatch({ type: types.GET_USER_DETAILS_SUCCESS, payload: response.user });
      } else {

      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.GET_USER_DETAILS_FAILURE });
    });
};

export const deleteUser = (data, extraData) => dispatch => {
  dispatch({ type: types.DELETE_USER_REQUEST });
  post(Endpoint.deleteUser, data)
    .then(response => {
      if (response.status === 'success') {
        Toast.notifySuccessMsg(response?.message)
        extraData.closeAlert()
        dispatch({ type: types.DELETE_USER_SUCCESS });
      } else {

      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.DELETE_USER_FAILURE, payload: err });
    });
};
export const getUserStatistics = (data, extraData = '') => dispatch => {
  dispatch({ type: types.GET_USER_STATISTICS_REQUEST });
  post(Endpoint.getUserStatistics, data)
    .then(response => {
      if (response.status === 'success') {
        Toast.notifySuccessMsg(response?.message)
        dispatch({ type: types.GET_USER_STATISTICS_SUCCESS, payload: response });
      } else {
        dispatch({ type: types.GET_USER_STATISTICS_FAILURE, payload: response?.message });
      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.GET_USER_STATISTICS_FAILURE, payload: err });
    });
};
export const getFeeds = (data, extraData = {}) => dispatch => {
  let selectedKeyName = getFeedKey(data)
  dispatch({ type: types.GET_FEEDS_REQUEST });
  post(Endpoint.getAllPosts, data)
    .then(response => {
      if (response.status === 'success') {
        if (!_isEmpty(extraData) && data.strMethod === 'getreportedpost') {
          let data = response?.objUserEntity?.lstFeeds
          extraData.callbackFn(data?.length);
        }
        dispatch({ type: types.GET_FEEDS_SUCCESS, payload: { lstFeeds: response?.objUserEntity?.lstFeeds || [], extraData: { ...data, selectedKeyName } } });
      } else {
        Toast.notifyErrorMsg(response?.message)
        dispatch({ type: types.GET_FEEDS_FAILURE, payload: response?.message });
      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.GET_FEEDS_FAILURE, payload: err });
    });
};
export const getUserInfo = (data, extraData = {}) => dispatch => {
  dispatch({ type: types.GET_USER_FEEDS_REQUEST });
  post(Endpoint.getUserInfo, data)
    .then(response => {
      if (response.status === 'success') {
        dispatch({ type: types.GET_USER_FEEDS_SUCCESS, payload: { lstFeeds: response?.lstFeeds || [], extraData: { ...data } } });
      } else {
        Toast.notifyErrorMsg(response?.message)
        dispatch({ type: types.GET_USER_FEEDS_FAILURE, payload: response?.message });
      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.GET_FEEDS_FAILURE, payload: err });
    });
};
export const updateReportedPostStatus = (data, extraData) => dispatch => {
  dispatch({ type: types.UPDATE_REPORTED_POST_STATUS_REQUEST });
  post(Endpoint.updateReportedPostStatus, data)
    .then((response) => {
      if (response.status === 'success') {
        if (!_isEmpty(extraData)) {
          extraData.callBackFunc()
        }
        Toast.notifySuccessMsg(response?.message)
        dispatch({ type: types.UPDATE_REPORTED_POST_STATUS_SUCCESS })
      }
      else {
        Toast.notifyErrorMsg(response?.message)
        dispatch({ type: types.UPDATE_REPORTED_POST_STATUS_FAILURE })

      }
    })
    .catch((err) => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.UPDATE_REPORTED_POST_STATUS_FAILURE })
    })
}
export const handleKeys = (key, value) => dispatch => {
  dispatch({ type: types.HANDLE_KEYS, payload: { key, value } });
};

export const updateFeeds = data => dispatch => {
  dispatch({ type: types.UPDATE_FEED, payload: data })
}

export const abilityToMakePremium = (data, extraData) => dispatch => {
  dispatch({ type: types.ABILITY_TO_MAKE_PREMIUM_REQUEST });
  post(Endpoint.abilityToMakePremium, data)
    .then(response => {
      if (response.status === 'success') {
        dispatch({ type: types.ABILITY_TO_MAKE_PREMIUM_SUCCESS,  payload: {data } });
        Toast.notifySuccessMsg(response?.output?.ErrorMessage);
        if (!_isEmpty(extraData)) {
          extraData.callBackFunc()
        }
      } else {
        Toast.notifyErrorMsg(response?.message)
        dispatch({ type: types.ABILITY_TO_MAKE_PREMIUM_FAILURE, payload: response?.message });
      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.ABILITY_TO_MAKE_PREMIUM_FAILURE, payload: err });
    });
}

export const abilityToChangeExpiry= (data, extraData) => dispatch => {
  dispatch({ type: types.ABILITY_TO_CHANGE_EXPIRY_REQUEST });
  post(Endpoint.abilityToChangeExpiry, data)
    .then(response => {
      if (response.status === 'success') {
        Toast.notifySuccessMsg(response?.output?.ErrorMessage)
        dispatch({ type: types.ABILITY_TO_CHANGE_EXPIRY_SUCCESS, payload: {data } });
        if (!_isEmpty(extraData)) {
          extraData.callBackFunc()
        }
      } else {
        Toast.notifyErrorMsg(response?.message)
        dispatch({ type: types.ABILITY_TO_CHANGE_EXPIRY_FAILURE, payload: response?.message });
      }
    })
    .catch(err => {
      Toast.notifyErrorMsg(err)
      dispatch({ type: types.ABILITY_TO_CHANGE_EXPIRY_FAILURE, payload: err });
    });
}

export const getAIMembershipTarrif = () => async (dispatch) => {
  get(Endpoint.GetAIMembershipTariffs)
    .then(res => {
      if (res.status === "success") {
        dispatch({ type: types.GET_TARIFF_DETAILS, payload: res?.output })
      }
    })
}

export const updateBulkReportedPostStatus = (data, extraData) => dispatch => {
  dispatch({ type: types.UPDATE_BULK_REPORTED_POST_STATUS_REQUEST });
  post(Endpoint.updateBulkReportedPostStatus, data)
    .then((response) => {
      if (response.status === 'success') {        
        Toast.notifySuccessMsg(response?.message)
        dispatch({ type: types.UPDATE_BULK_REPORTED_POST_STATUS_SUCCESS })
      }
      else {
        Toast.notifyErrorMsg(response?.message)
        dispatch({ type: types.UPDATE_BULK_REPORTED_POST_STATUS_FAILURE })

      }
      if (!_isEmpty(extraData)) {
        extraData.callBackFunc()
      }
    })
    .catch((err) => {
      Toast.notifyErrorMsg(err)
      if (!_isEmpty(extraData)) {
        extraData.callBackFunc()
      }
      dispatch({ type: types.UPDATE_BULK_REPORTED_POST_STATUS_FAILURE })
    })
}

