import React from 'react'
import { CButton, CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react'
import Spinner from './Spinner'
export default function AlertModal({
  displayModal,
  header = '',
  message = '',
  cancelButtonLabel = '',
  mainButtonLabel = '',
  onMainButtonPress = () => { },
  onCancelButtonPress = () => { },
  loading = false,
  hideCancelButton = false
}) {

  return (
    <>
      <CModal
        show={displayModal}
        onClose={onCancelButtonPress}
        closeOnBackdrop={false}
        fade={true}
      >
        <CModalHeader className='p-5-font-l' >{header}</CModalHeader>
        <CModalBody className='p-1-font-m'>
          {message}
        </CModalBody>
        <CModalFooter className='p-2' >
          <CButton onClick={onMainButtonPress} color="primary" disabled={loading}>
            <Spinner loading={loading} />
            {mainButtonLabel}
          </CButton>{' '}
          {hideCancelButton ? null : <CButton color="secondary" onClick={onCancelButtonPress}>{cancelButtonLabel}</CButton>}
        </CModalFooter>
      </CModal>
    </>
  )

}
