import { types } from '../actionTypes/UserActionTypes'

const UserReducer = (state = [], action) => {
    const { payload } = action
    switch (action.type) {
        case types.GET_USERS_REQUEST:
            return {
                ...state,
                userListLoading: true
            }
        case types.GET_USERS_SUCCESS:
            return {
                ...state,
                userListLoading: false,
                userList: payload.users,
                totalUsers: payload.totalRecords
            }
        case types.GET_USERS_FAILURE:
            return {
                ...state,
                userListLoading: false
            }
        case types.GET_USER_DETAILS_REQUEST:
            return {
                ...state,
                userDetailsLoading: true
            }
        case types.GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetailsLoading: false,
                userDetails: payload
            }
        case types.GET_USER_DETAILS_FAILURE:
            return {
                ...state,
                userDetailsLoading: false
            }
        case types.DELETE_USER_REQUEST:
            return {
                ...state,
                deleteUserLoading: true
            }
        case types.DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteUserLoading: false,
            }
        case types.DELETE_USER_FAILURE:
            return {
                ...state,
                deleteUserLoading: false
            }
        case types.GET_USER_STATISTICS_REQUEST:
            return {
                ...state,
                userStatisticsLoading: true
            }
        case types.GET_USER_STATISTICS_SUCCESS:
            return {
                ...state,
                userStatisticsLoading: false,
                userStatisticsData: payload?.users,
                totalUsers: payload?.totalRecords

            }
        case types.GET_USER_STATISTICS_FAILURE:
            return {
                ...state,
                userStatisticsLoading: false
            }
        case types.GET_FEEDS_REQUEST:
            return {
                ...state,
                getFeedsLoading: true
            }
        case types.GET_FEEDS_SUCCESS:
            let selectedKeyName = payload?.extraData?.selectedKeyName
            let updatedData = []
            if (payload?.extraData?.StartIndex > 1) {
                updatedData = [...state[selectedKeyName], ...payload?.lstFeeds]
            } else {
                updatedData = [...payload?.lstFeeds]
            }
            return {
                ...state,
                [selectedKeyName]: updatedData,
                getFeedsLoading: false
            }
        case types.GET_FEEDS_FAILURE:
            return {
                ...state,
                getFeedsLoading: false
            }
        case types.GET_USER_FEEDS_REQUEST:
            return {
                ...state,
                getUserFeedsLoading: true,
            }
        case types.GET_USER_FEEDS_SUCCESS:
            let tempData = [];
            if (payload?.extraData?.StartIndex > 1) {
                tempData = [...state['getUserFeeds'], ...payload?.lstFeeds]
            } else {
                tempData = [...payload?.lstFeeds]
            }
            return {
                ...state,
                getUserFeedsLoading: false,
                getUserFeeds: tempData,
            }
        case types.GET_USER_FEEDS_FAILURE:
            return {
                ...state,
                getUserFeedsLoading: false,
            }
        case types.UPDATE_REPORTED_POST_STATUS_REQUEST:
            return {
                ...state,
                reportedPostLoading: true
            }
        case types.UPDATE_REPORTED_POST_STATUS_SUCCESS:
            return {
                ...state,
                reportedPostLoading: false
            }
        case types.UPDATE_REPORTED_POST_STATUS_FAILURE:
            return {
                ...state,
                reportedPostLoading: false
            }
        case types.HANDLE_KEYS:
            return {
                ...state,
                [payload.key]: payload.value
            }
        case types.UPDATE_FEED:
            return {
                ...state,
                ...action.payload
            }
        case types.ABILITY_TO_MAKE_PREMIUM_REQUEST:
            return {
                ...state,
                premiumLoading: true
            }
        case types.ABILITY_TO_MAKE_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumLoading: false
            }
        case types.ABILITY_TO_MAKE_PREMIUM_FAILURE:
            return {
                ...state,
                premiumLoading: false
            }
        case types.ABILITY_TO_CHANGE_EXPIRY_REQUEST:
            return {
                ...state,
                expiryLoading: true
            }
        case types.ABILITY_TO_CHANGE_EXPIRY_SUCCESS:
            return {
                ...state,
                expiryLoading: false
            }
        case types.ABILITY_TO_CHANGE_EXPIRY_FAILURE:
            return {
                ...state,
                expiryLoading: false
            }
        case types.GET_TARIFF_DETAILS: {
            const tempPaymentTypes = payload.map((item) => item.TariffTypeKey.toLowerCase());
            let paymentTypes = [...new Set(tempPaymentTypes)]; //remove the duplicate tempPaymentTypes
            const activePaymentTypes = [];
            if (paymentTypes.includes('time')) {
                activePaymentTypes.push('time')
            }
            if (paymentTypes.includes('day')) {
                activePaymentTypes.push('day')
            }
            if (paymentTypes.includes('month') || paymentTypes.includes('week') || activePaymentTypes.length === 0) {
                activePaymentTypes.push('month')
            }
            return { ...state, tarrifArr: [...payload], activePaymentTypes, paymentTypes }
        }
        case types.UPDATE_BULK_REPORTED_POST_STATUS_REQUEST:
            return {
                ...state,
                bulkReportedPostLoading: true
            }
        case types.UPDATE_BULK_REPORTED_POST_STATUS_SUCCESS:
            return {
                ...state,
                bulkReportedPostLoading: false
            }
        case types.UPDATE_BULK_REPORTED_POST_STATUS_FAILURE:
            return {
                ...state,
                bulkReportedPostLoading: false
            }
        default:
            return state
    }
}
export default UserReducer;
