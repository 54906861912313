const store = window.localStorage;

export const getStorage = (key) => JSON.parse(store.getItem(key));

export const setStorage = (key, value) => store.setItem(key, JSON.stringify(value));

export const removeStorage = (key) => store.removeItem(key);

export const clearAll = (key) => store.clear();

export const hasStorage = (key) => {
  const item = getStorage(key);
  return typeof item !== 'undefined' && item !== null;
};
