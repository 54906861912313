import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Path from 'src/constants/path';
import { hasStorage, getStorage } from './localStorage'

function PublicRoute({ component: Component, ...rest }) {
    let isLogged = false
    if (hasStorage('isLogged')) {
        isLogged = getStorage('isLogged')
    };
    return (
        <Route
            {...rest}
            render={
                (props) => isLogged ? <Redirect to={Path.dashboard} /> : <Component {...props} />
            }
        />
    );
}

export default PublicRoute;
