import { createStore, applyMiddleware } from 'redux'
import { reducer } from './services/reducers/index'
import initialState from './services/initialState'
import thunk from 'redux-thunk'

// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

const store = createStore(reducer, initialState, applyMiddleware(thunk))
export default store