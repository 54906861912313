import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CFade } from '@coreui/react'

import { hasStorage, getStorage } from './localStorage'
import Path from '../constants/path'


function PrivateRoute({ component: Component, ...rest }) {
    let isLogged = false
    if (hasStorage('isLogged')) {
        isLogged = getStorage('isLogged')
    };
    return (
        <Route
            {...rest}
            render={
                (props) => isLogged ? <CFade><Component {...props} /></CFade> : <Redirect to={Path.login} />
            }
        />
    );
}


export default PrivateRoute;
