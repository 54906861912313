
const userInitialState = {
  userList: [],
  userListLoading: false,
  userDetails: {},
  userDetailsLoading: false,
  deleteUserLoading: false,
  sidebarShow: 'responsive',
  totalUsers: 0,
  userStatisticsData: [],
  userStatisticsLoading: false,
  displaySessionAlert: false,
  getFeedsLoading: false,
  allPosts: [],
  postsToReview: [],
  reportedPosts: [],
  getUserFeeds: [],
  getUserFeedsLoading: false,
  reportedPostLoading: false,
  premiumLoading: false,
  expiryLoading: false,
  tarrifArr: [],
  activePaymentTypes:[],
  paymentTypes:[],
  bulkReportedPostLoading: false,
}
const groupInitialState = {
  allGroupsList: [],
  groupListLoading: false,
  updateGroupListLoading: false,
  deleteGroupLoading: false,
  groupDetails: {},
  totalGroups: 0,
  removeUserLoading: false,
  feedLimit: 10,
  groupFeedsLoading: false,
  editGroupLoading: false,
  hashTagData: [],
  makeUserAdminLoading: false
}

const initialState = {
  user: userInitialState,
  group: groupInitialState,
}

export default initialState